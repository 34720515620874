<template>
  <main class="main-container">
    <div class="w-full relative">
        <img :src="detail.promo_image_url" alt="" class="w-full h-full">
        <!-- <img src="@/assets/images/icon-close.svg" alt="" class="w-8 h-8 absolute top-5 right-5 cursor-pointer"> -->
    </div>
    <section class="info info--sk">
      <div class="card-info card-info--sk">
          <h1 class="card-info__heading text-2xl mt-[40px]">{{ detail.promo_name }}</h1>
          <div class="card-info__body">
            <div class="pb-4">
                <p class="text-[#8F8F92] pb-0 text-bold">Kode Voucher</p>
                <div class="flex justify-between items-center text-base font-semibold p-3">
                    <span class="">{{ detail.voucher_code }}</span>
                    <img @click="clickToCopy" src="@/assets/images/icon-copy.svg" class="w-6 h-6 cursor-pointer">
                </div>
            </div>
            <div v-html="detail.promo_description"/>
            <div class="flex flex-col gap-2 items-center mt-8">
                <span class="mt-6">Powered by</span>
                <img src="@/assets/images/logo-venteny.svg">
            </div>
          </div>
      </div>
    </section>
</main>
</template>
<script>
import { REDEEM_PROMO_VOUCHER } from '../../store/modules/voucher';

export default {
  name: 'RedeemVoucher',
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.redeemVoucherPromo();
  },
  methods: {
    redeemVoucherPromo() {
      this.$store.dispatch(REDEEM_PROMO_VOUCHER, {
        id: this.$route.params.promo_id,
      }).then((result) => {
        this.detail = result;
      }).catch(() => {
        this.$router.push({ name: '404' });
      });
    },
    async clickToCopy() {
      await navigator.clipboard.writeText(this.detail.voucher_code);
      this.$toast({
        message: 'Kode voucher berhasil\ndisalin.',
        className: 'multiline',
      });
    },
  },
};
</script>
<style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box
  }

  img {
    max-width: 100%;
  }

  .main-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }

  body {
    background-color: #FAFAFC;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    color: #1F272E;
  }

  .body--sk {
    background-color: #ffffff;
  }

  .heading {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
  }

  .info {
    padding: 0px 20px;
    padding-bottom: 100px;
  }

  .info--sk {
    padding: 0px;
    /* padding-bottom: 190px; */
  }

  .card-info {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #E2E2EE;
    transform: translateY(-40px);
  }

  .card-info__heading {
    padding: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }

  .card-info__datetime {
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 10px 10px 10px 20px;
    color: #FB7418;
    background-color: #FEF3E8;
  }

  .card-info__datetime span {
    display: block;
    padding-left: 10px;
  }

  .card-info__body {
    padding: 15px 20px 25px 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .card-info--sk {
    border-radius: 0px;
    box-shadow: unset;
  }

  .box-location {
    background-color: #FAFAFC;
    padding: 10px 20px 10px 10px;
    display: flex;
    margin: 24px 0px;
  }

  .box-location__info {
    padding-left: 10px;
  }

  .box-location__info .heading {
    margin-bottom: 4px;
  }

  .box-location__info p {
    font-weight: 300;
    line-height: 17.73px;
    font-size: 13px;
  }

  /* .box-validity_period {
    font-size: 16px;
} */

  .action-sk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1F272E;
    margin-bottom: 30px;
  }

  .main-cta {
    padding: 13px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.37px;
    background-color: #FB7418;
    border-radius: 8px;
    display: block;
    color: #ffffff;
    font-weight: 600;
  }

  .main-cta span {
    font-weight: 900;
  }

  .floating-cta {
    background-color: #ffffff;
    box-shadow: 0px -4px 4px 0px #E2E2EE;
    position: fixed;
    padding: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 13px;
    line-height: 20px;
  }

  ul li {
    margin-bottom: 4px;
  }

  .multiline {
    white-space: pre-wrap;
  }

</style>
